<template>
	<img
		v-bind="attrs"
		v-on="$listeners"
		class="obi-image"
		:alt="attrs.alt || 'alt'"
		:class="classList"
	/>
</template>

<script>
import { keys, omit } from "lodash";

export default {
	name: "ObiImage",
	props: {
		full: {
			type: Boolean,
			default: false,
		},
		fluid: {
			type: Boolean,
			default: false,
		},
		rounded: {
			type: Boolean,
			default: false,
		},
		thumbnail: {
			type: Boolean,
			default: false,
		},
		fitContain: {
			type: Boolean,
			default: false,
		},
		fitCover: {
			type: Boolean,
			default: false,
		},
		grayscale: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		attrs() {
			return omit(this.$attrs, keys(this.$props));
		},
		classList() {
			return [
				this.full && "img-full",
				this.fluid && "img-fluid",
				this.rounded && "rounded",
				this.fitCover && "img-fit-cover",
				this.thumbnail && "img-thumbnail",
				this.grayscale && "img-grayscale",
				this.fitContain && "img-fit-contain",
			];
		},
	},
};
</script>

<style lang="scss" scoped>
.obi-image {
	&.img-full {
		width: 100%;
	}

	&.img-fit-contain {
		object-fit: contain;
		object-position: center;
	}

	&.img-fit-cover {
		object-fit: cover;
		object-position: center;
	}

	&.img-grayscale {
		filter: grayscale(1);
	}
}
</style>
